.posts {
  width: 100%;
  max-width: 760px;
  text-align: left;
  margin: 40px auto;
  margin-bottom: 10px;
  
  @media #{$media-size-tablet} {
    max-width: 660px;
  }

  h1 {
    margin-bottom: 0;
  }

  &-yearly {
    border-top: 1px dotted #444;
    margin-top: 1.2em;
    margin-bottom: 0;
    padding-top: 1.1em;
  }

  &-group {
    display: flex;
    line-height: normal;

    @media #{$media-size-tablet} {
      display: block;
    }
  }

  &-list {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .post {
    &-title {
      font-size: 1rem;
      margin: 5px 0 5px 0;

      &:hover {
        color: #eee;
      }
    }

    &-tags {
      font-size: 0.9em;
    }
    &-month {
      padding-top: 8px;
      margin-right: 1.8em;
      width: 150px;
      @include dimmed;

      @media #{$media-size-tablet} {
        margin: -6px 0 4px;
      }
    }

    &-item {
      a {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 4px 0;
        text-decoration: none;
      }
    }

    &-day {
      flex-shrink: 0;
      margin-left: 1em;
      @include dimmed;
    }

    &-meta {
      font-size: 0.9em;
      color: #888;
      padding-top: 30px;
      .post-date {

      }
      .read-more {
        background: none;
        display: inline-flex;
        font-variant: all-petite-caps;
        font-weight: 500;
        opacity: 0.8;
        padding: 0 6px;
        color: #666;

        &:hover {
          color: #ccc;
        }
      }
    }
  }
  .on-list {
    border-bottom: 1px dotted #444;
  }
}
